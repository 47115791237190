.table {
    text-align: start;
    margin-bottom: 0px;
}


.table thead th{

    border-top:0px;
    border-bottom:0px;
    text-transform: lowercase;
    font-weight: normal;
    border-left: 3px solid dodgerblue;


}

.table thead th{
    margin-bottom : 20px;
}

.table th {
    padding: 1px;
    padding-left: 5px;

}

.table td {
    border-left: 5px solid white;
    padding: 2px;
    vertical-align: inherit;
    border-top: 0px;
    padding-left: 5px;

}

.table a{
    color: inherit;
}

.table a:hover{
    text-decoration: none;
}

.Table-cell-white {
    background-color: #e5e5e5 !important;
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
    background-color: white;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color:#e5e5e5;
}

.Table-ID {
    width: 75px;
    min-width: 75px;
    max-width: 75px;
}

.Table-invoice_name{
    width: 220px;
    min-width: 220px;
    max-width: 220px;
}

.Table-salesperson_name{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.Table-postcode{
    width: 60px;
    min-width: 60px;
    max-width: 60px;
}

.Table-city{
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}

.Table-address{
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}

.Table-phone{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.Table-invoice{
    width: 115px;
    min-width: 115px;
    max-width: 115px;
}

.Table-method{
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.Table-email{
    width: 250px;
    min-width: 250px;
    max-width: 250px;
}

.Table-chkbox{

    width: 60px;
    min-width: 60px;
    max-width: 60px;
}

td.Table-chkbox {
    padding-top: 3px;
    text-align: center;
}

.Table-btn{
    background-color: white!important;
    border-color: white !important;
    width: 35px;
    min-width: 35px;
    max-width: 35px;
}

.Table-filler{
    background-color: white !important;
    border-color: white !important;
    width:auto;
    min-width: unset;
    max-width: unset;
}

.Table-contract_no{
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}

.Table-date{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}

.Table-summ{
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

td.Table-summ{
 text-align: right;
}
.Table-title-file{
    width: 500px;
    min-width: 500px;
    max-width: 500px;
}

.Table-uploaded-file{
    width: 130px;
    min-width: 130px;
    max-width: 130px;
}

.Table-attachmentFileId{
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    text-align: center !important;
}
