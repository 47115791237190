.modal-content {
        border-radius: 1.5rem !important;
        border-bottom-left-radius: 0px !important;
        padding: 20px;

        font-size: 13px;
        letter-spacing: 1px;
        box-shadow: 15px 15px 10px rgba(0,0,0,0.3);
}

.modal-body {
        padding-bottom: 0px !important;
}

.modal-dialog_wide {
        width: 65vw !important;
        max-width: 65vw !important;
        display: block;
        border-radius: 6px !important;
}

.modal-dialog_wide>.modal-content {
        width: 65vw !important;
        max-width: 65vw !important;
        display: block;
}

.modal-dialog_mid {
        width: 55vw !important;
        max-width: 55vw !important;
        display: block;
        border-radius: 6px !important;
        margin-top: 50px !important;
}

.modal-dialog_mid>.modal-content {
        width: 55vw !important;
        max-width: 55vw !important;
        display: block;
}

.modal-dialog_sml {
    width: 30vw !important;
    max-width: 30vw !important;
    display: block;
    border-radius: 6px !important;
    margin-top: 60px !important;
}

.modal-dialog_sml>.modal-content {
    width: 30vw !important;
    max-width: 30vw !important;
    display: block;
}

.modal-backdrop.show {
        opacity: 0 !important;
}

.modal-header{
        padding-bottom: 5px !important;
        border-bottom: 0px  !important;
}

.modal-header>.modal-title {
    width: 100vw;
}

form {
    padding: 0px !important;
}

/*Moveable CSS*/
.container-moveable {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-42%, 50%);
}

.moveable {
        position: relative;
}

.origin{
        position: absolute;
        left: 0;
}

.moveable-line{
        opacity: .0;
}

.delete-contract-div{
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0px auto 20px;
        width: 100%
}
