@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.modal-content {
        border-radius: 1.5rem !important;
        border-bottom-left-radius: 0px !important;
        padding: 20px;

        font-size: 13px;
        letter-spacing: 1px;
        box-shadow: 15px 15px 10px rgba(0,0,0,0.3);
}

.modal-body {
        padding-bottom: 0px !important;
}

.modal-dialog_wide {
        width: 65vw !important;
        max-width: 65vw !important;
        display: block;
        border-radius: 6px !important;
}

.modal-dialog_wide>.modal-content {
        width: 65vw !important;
        max-width: 65vw !important;
        display: block;
}

.modal-dialog_mid {
        width: 55vw !important;
        max-width: 55vw !important;
        display: block;
        border-radius: 6px !important;
        margin-top: 50px !important;
}

.modal-dialog_mid>.modal-content {
        width: 55vw !important;
        max-width: 55vw !important;
        display: block;
}

.modal-dialog_sml {
    width: 30vw !important;
    max-width: 30vw !important;
    display: block;
    border-radius: 6px !important;
    margin-top: 60px !important;
}

.modal-dialog_sml>.modal-content {
    width: 30vw !important;
    max-width: 30vw !important;
    display: block;
}

.modal-backdrop.show {
        opacity: 0 !important;
}

.modal-header{
        padding-bottom: 5px !important;
        border-bottom: 0px  !important;
}

.modal-header>.modal-title {
    width: 100vw;
}

form {
    padding: 0px !important;
}

/*Moveable CSS*/
.container-moveable {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-42%, 50%);
}

.moveable {
        position: relative;
}

.origin{
        position: absolute;
        left: 0;
}

.moveable-line{
        opacity: .0;
}

.delete-contract-div{
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0px auto 20px;
        width: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
  font-family: Font Awesome 5 Free;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/static/media/fa-regular-400.fc9c63c8.eot);
  src: url(/static/media/fa-regular-400.fc9c63c8.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/fa-regular-400.e07d9e40.woff2) format("woff2"),
    url(/static/media/fa-regular-400.e5770f98.woff) format("woff"),
    url(/static/media/fa-regular-400.59215032.ttf) format("truetype"),
    url(/static/media/fa-regular-400.8fdea4e8.svg#fontawesome) format("svg");
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(/static/media/fa-brands-400.03783c51.eot);
  src: url(/static/media/fa-brands-400.03783c51.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/fa-brands-400.7559b377.woff2) format("woff2"),
    url(/static/media/fa-brands-400.fe9d62e0.woff) format("woff"),
    url(/static/media/fa-brands-400.ed2b8bf1.ttf) format("truetype"),
    url(/static/media/fa-brands-400.377b721b.svg#fontawesome) format("svg");
}

@font-face {
  font-family: Font Awesome 5 Bold;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/static/media/fa-solid-900.ef3df984.eot);
  src: url(/static/media/fa-solid-900.ef3df984.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/fa-solid-900.b5cf8ae2.woff2) format("woff2"),
    url(/static/media/fa-solid-900.4bced7c4.woff) format("woff"),
    url(/static/media/fa-solid-900.acf50f59.ttf) format("truetype"),
    url(/static/media/fa-solid-900.013259eb.svg#fontawesome) format("svg");
}

@font-face {
  font-family: "Font Awesome 5 Font";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("/static/media/Font Awesome 5 Free-Regular-400.9b9b7414.eot");
  src: url("/static/media/Font Awesome 5 Free-Regular-400.9b9b7414.eot?#iefix")
    format("embedded-opentype");
}

.App {
  text-align: center;
  font-family: Font Awesome 5 Free;
  letter-spacing: 1px;
  font-size: 13px;
}

.App-icon {
  font-family: "Font Awesome 5 Brands";
  letter-spacing: 1px;
  font-size: 16px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  background-size: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-head {
  background: linear-gradient(180deg, #e5e5e5 50%, #ffffff 50%);
  padding-top: 30px;
  min-height: 300px;
}

.discount-header {
  padding-top: 10px;
}

.discount-body-web {
  display: block;
}

.discount-body-mobile {
  display: none;
}

#mobile-navbar {
  display: none;
}

#desktop-navbar {
  display: flex;
  padding-left: 100px;
}

@media only screen and (max-width: 500px) {
  /* For mobile phones: */

  .App-head {
    min-height: 100px;
  }

  .discount-header {
    padding-top: 30px;
  }
  .discount-body-web {
    display: none;
  }

  .discount-body-mobile {
    display: block;
  }

  .alert {
    width: 80%;
  }

  #mobile-navbar {
    display: block;
  }

  #desktop-navbar {
    display: none;
  }

  .App-label {
    text-align: center;
    padding: 0;
  }
}

.App-label {
  text-align: left;
  padding: 0.1rem 0.2rem;
  padding-right: 0px;
}

.App-label-title {
  font-weight: bolder;
  font-size: 16px;
  text-transform: uppercase;
}

.App-input {
  background-color: #282c34;
  border-radius: 0rem;
}

.Login-Box {
  padding-top: 100px;
}

.App-link {
  color: #61dafb;
}

.App-navbar-text {
  padding-top: 5px;
  font-size: 16px;
}

.App-navbar-text-top {
  padding-top: 35px;
  font-size: 16px;
}

form {
  padding: 5px;
}

input,
textarea {
  background-color: #e5e5e5 !important;
  background-image: none !important;
  border: 0px !important;
}

input:disabled {
  background-color: white !important;
}

input:-ms-input-placeholder {
  background-color: #e5e5e5 !important;
}

input:placeholder-shown {
  background-color: #e5e5e5 !important;
}

.App-img-sm {
  vertical-align: top;
  padding-top: 1px;
  height: 18px;
}

input:-webkit-autofill {
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 0 0px 9999px #e5e5e5;
}

.navbar {
  padding: 0px;
  align-items: start;
}

.container {
  padding: 0px;
}

.input-group-text {
  padding: 0px 5px 0px 0px;
  text-align: left;
  font-size: inherit;
  background-color: inherit;
  border: 0px;
  min-width: 125px;
}

.input-group-short {
  min-width: 50px !important;
}

.input-group {
  padding-bottom: 10px;
}

.input-search {
  background-color: yellow !important;
}

.btn {
  background-color: white;
  padding: 0.1rem 0.3rem;
  border-color: dodgerblue;
  border-width: 3px;
  border-radius: 0.5rem;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-stretch: ultra-expanded;
  color: black;
  font-size: 13px;
  min-width: 90px;
}

.App-btn-group {
  max-width: 75px !important ;
  min-width: 10px;
  background-color: transparent;
  border: 0px;
  color: #212529;
  letter-spacing: 1px;
  font-weight: normal;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.App-btn-group.active {
  background-color: transparent !important;
  border-width: 0px !important;
  color: black !important;
  font-weight: bolder !important;
  z-index: 1;
  text-align: center;
}

.App-btn-group:hover {
  background-color: transparent !important;
  border: 0px !important;
  color: #3959bd !important;
  font-weight: bolder !important;
  z-index: 1;
  text-align: center;
}

.App-col-r-search {
  padding-left: 30px;
}

.App-col-l-search {
  padding-right: 30px;
}

:target {
  padding: 0.1rem 0.3rem !important;
  background-color: transparent !important;
  border: 0px !important;
  font-weight: bolder !important;
  z-index: 1;
}

.nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.nav-link:hover {
  color: rgba(0, 0, 0, 0.5);
}

.nav-item.active {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0px 0px;
}

.form-control {
  border-radius: 0;
  padding: 0.1rem 0.2rem;
  height: calc(1.5em + 0.1rem + 2px);
  font-size: inherit;
  background-color: #e5e5e5;
}

.form-group {
  margin-bottom: 0.2rem;
}
.App-Option-separator {
  padding-top: 5px !important;
  max-width: 2px;
}

.App-Option-Select {
  padding-top: 4px !important;
  text-align: center;
}

.App-page-label {
  min-width: 125px;
  max-width: 125px;
  width: 125px;
}

.App-page-label-wider {
  min-width: 350px;
  max-width: 350px;
  width: 350px;
}

.App-modal-label {
  min-width: 200px;
  max-width: 200px;
}

.App-searchLine {
  vertical-align: bottom;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-icon-button {
  border: 0px !important;
  padding: 0px !important;
  margin: 0.5px 0px 3px !important;
  min-width: 0px !important;
  height: 18px !important;
  background-color: transparent;
}
.App-icon-button:active {
  background-color: transparent !important;
  border: 0px !important;
  color: black !important;
  font-weight: bolder !important;
  z-index: 1;
}
.App-icon-button:hover {
  background-color: transparent !important;
  border: 0px !important;
  color: black !important;
  font-weight: bolder !important;
  z-index: 1;
}

.App-Alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
}

.alert {
  position: fixed;
  top: 15px;
  left: 35%;
  width: 30%;
  z-index: 999999;
}

.reportspage {
  position: relative;
  /*top: 15px;
  left: 35%;*/
  width: 30%;
  /*z-index: 999999;*/
}

.default-alert {
  position: static;
  width: 100%;
}

.App-icon-width {
  width: 18px;
  max-width: 18px;
  align-self: end;
}

.App-fill-width {
  width: available;
}

.textarea {
  height: 60px;
  min-height: 60px;
  max-height: 60px;
}

.App-select {
  width: 325px;
}

.App-select-invoice {
  width: 272px;
}

.App-select-file {
  width: 287px;
}

.App-select-file-format {
  width: 163px;
}

.App-select-reports {
  width: 140px;
}

.App-btn-small {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}
.__react_component_tooltip {
  padding: 3px 5px !important;
  border-radius: 0.5rem;
}

.input-currency {
  text-align: right;
}

.input-currency:disabled {
  background-color: white !important;
}

.number-minw {
  min-width: 100px !important;
}

.Excel-export {
  font-size: 18px;
  color: #1e90ff;
}

.Yellow-bold-cell {
  background-color: yellow;
  font-weight: 700;
}

/*Moveable CSS*/
.container-moveable {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-42%, 50%);
}

.moveable-img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.firebrick {
  -webkit-filter: invert(10%) sepia(97%) saturate(4945%) hue-rotate(358deg)
    brightness(98%) contrast(113%);
          filter: invert(10%) sepia(97%) saturate(4945%) hue-rotate(358deg)
    brightness(98%) contrast(113%);
}

.forestgreen {
  -webkit-filter: invert(40%) sepia(74%) saturate(558%) hue-rotate(71deg)
    brightness(88%) contrast(90%);
          filter: invert(40%) sepia(74%) saturate(558%) hue-rotate(71deg)
    brightness(88%) contrast(90%);
}

.moveable-div {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  font-size: 18;
  display: inline-block;
}

.moveable {
  position: relative;
}

.origin {
  position: absolute;
  left: 0;
}

.moveable-line {
  opacity: 0;
}

.faBlue {
  color: dodgerblue;
  font-size: 1.4em;
}

.faRed {
  color: firebrick;
  font-size: 1.4em;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
}

.table {
    text-align: start;
    margin-bottom: 0px;
}


.table thead th{

    border-top:0px;
    border-bottom:0px;
    text-transform: lowercase;
    font-weight: normal;
    border-left: 3px solid dodgerblue;


}

.table thead th{
    margin-bottom : 20px;
}

.table th {
    padding: 1px;
    padding-left: 5px;

}

.table td {
    border-left: 5px solid white;
    padding: 2px;
    vertical-align: inherit;
    border-top: 0px;
    padding-left: 5px;

}

.table a{
    color: inherit;
}

.table a:hover{
    text-decoration: none;
}

.Table-cell-white {
    background-color: #e5e5e5 !important;
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
    background-color: white;
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color:#e5e5e5;
}

.Table-ID {
    width: 75px;
    min-width: 75px;
    max-width: 75px;
}

.Table-invoice_name{
    width: 220px;
    min-width: 220px;
    max-width: 220px;
}

.Table-salesperson_name{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.Table-postcode{
    width: 60px;
    min-width: 60px;
    max-width: 60px;
}

.Table-city{
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}

.Table-address{
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}

.Table-phone{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.Table-invoice{
    width: 115px;
    min-width: 115px;
    max-width: 115px;
}

.Table-method{
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.Table-email{
    width: 250px;
    min-width: 250px;
    max-width: 250px;
}

.Table-chkbox{

    width: 60px;
    min-width: 60px;
    max-width: 60px;
}

td.Table-chkbox {
    padding-top: 3px;
    text-align: center;
}

.Table-btn{
    background-color: white!important;
    border-color: white !important;
    width: 35px;
    min-width: 35px;
    max-width: 35px;
}

.Table-filler{
    background-color: white !important;
    border-color: white !important;
    width:auto;
    min-width: unset;
    max-width: unset;
}

.Table-contract_no{
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}

.Table-date{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}

.Table-summ{
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

td.Table-summ{
 text-align: right;
}
.Table-title-file{
    width: 500px;
    min-width: 500px;
    max-width: 500px;
}

.Table-uploaded-file{
    width: 130px;
    min-width: 130px;
    max-width: 130px;
}

.Table-attachmentFileId{
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    text-align: center !important;
}

